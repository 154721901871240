:global {
  // .cal-option {
  //   width: 300px;
  //   text-align: center;
  //   cursor: pointer;
  //   transition: 0.3s all ease-in-out;

  //   .anticon {
  //     opacity: 0;
  //     position: absolute;
  //     top: 8px;
  //     right: 8px;
  //     color: #4096ff;
  //     transition: 0.3s all ease-in-out;
  //   }

  //   &.selected {
  //     border-color: #91caff;
  //     background-color: #e6f4ff;
  //     .anticon {
  //       opacity: 1;
  //     }
  //   }
  // }

  .payment-logo {
    justify-content: space-between;
    img {
      height: 2vw;
      width: auto;
      margin-bottom: 1vw;
    }
  }

  .ant-steps.ant-steps-label-vertical {
    .ant-steps-item-content {
      margin-top: 0.5rem;
    }
    .ant-steps-item-title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      white-space: nowrap;
    }
  }

  .ant-steps-item {
    .ant-avatar {
      color: var(--bs-button-color);
      font-size: 1rem;
    }
  }
  .ant-steps-item-active {
    .ant-avatar {
      background: var(--bs-primary);
    }
    .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      &::after {
        background: var(--bs-primary) !important;
      }
    }
  }
  .ant-steps-item-wait {
    &.ant-steps-item-disabled {
      .ant-avatar {
        opacity: 0.5;
      }
    }
  }
  .part-calculation-steps-wrapper {
    --step-border-color: #00000033;
    overflow: hidden;
    border: 1px solid var(--step-border-color);
    background: white;
    .part-calculation-steps {
      padding-top: 0;
      .ant-steps-item-title {
        font-size: 1rem;
        z-index: 3;
      }
    }

    .ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical) {
      .ant-steps-item {
        position: relative;
      }
      .ant-steps-item-custom .ant-steps-item-icon {
        display: none;
      }
    }
    .ant-steps.ant-steps-navigation {
      .ant-steps-item-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-inline-start: 0;
        padding: 0.8rem 0;

        .ant-steps-item-title {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
    }
    .ant-steps.ant-steps-navigation .ant-steps-item::after {
      top: 50%;
      width: calc(30px + 0.5rem);
      height: calc(30px + 0.5rem);
      border-top: 1px solid var(--step-border-color);
      border-inline-end: 1px solid var(--step-border-color);
      transform: translateY(-50%) translateX(-50%) rotate(45deg) skew(10deg, 10deg);
      z-index: 1;
    }

    .ant-steps.ant-steps-navigation {
      .ant-steps-item {
        &::before {
          min-height: 100%;
          transition: none;
        }
        &.ant-steps-item-finish,
        &.ant-steps-item-wait {
          &:not(.ant-steps-item-disabled) {
            &:hover {
              background: var(--bs-primary);
              &:after {
                background: var(--bs-primary);
              }
              .ant-steps-item-content {
                .ant-steps-item-title {
                  color: var(--step-item-title-text-color) !important;
                }
              }
            }
          }
          &.ant-steps-item-disabled {
            content: unset;
          }
        }
      }
    }
    .ant-steps-item::after {
      background: white;
    }

    .ant-steps-item-active {
      .ant-avatar-icon {
        background: var(--step-avatar-background);
        // border: 1px solid var(--step-avatar-border);
        color: var(--step-avatar-text-color);
        z-index: 1;
      }
      .ant-steps-item-content {
        .ant-steps-item-title {
          color: var(--step-item-title-text-color) !important;
        }
      }
    }
  }
  .ant-steps-item-finish {
    .ant-avatar {
      background: var(--bs-primary);
      opacity: 0.5;
    }
  }
  @media screen and (min-width: 768px), screen and (min-width: 1024px) {
    .part-calculation-steps-wrapper {
      .ant-steps.ant-steps-navigation {
        .ant-steps-item-active.ant-steps-item::after {
          background: var(--bs-primary) !important;
          // border-width: 0;
        }
      }
    }
  }
  
}
