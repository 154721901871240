@import "./assets/styles/variables";

.btn-logout {
  text-decoration: none;
  font-weight: 600;
}

.sub-l {
  font-size: 1.143rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.pointer {
  cursor: pointer;
}

.ant-steps-item-active {
  .ant-steps-item-title,
  .ant-steps-item-description {
    font-weight: bold;
  }
}

.ant-modal {
  .ant-modal-content {
    border-radius: var(--bs-app-border, 8px);
  }
  @media #{$tabletPortrait} {
    min-width: 80vw;
  }
}

.ant-modal-root .ant-modal-wrap {
  scroll-behavior: smooth;
}

.ant-modal.modal-body-scroll {
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-header {
    margin: 0;
    .ant-modal-title {
      padding: 0.8rem 1.25rem;
      font-size: 1.25rem;
    }
  }
  .ant-modal-body {
    position: relative;
    padding: 1rem 1.25rem;
    max-height: 62vh;
    overflow: auto;
    scroll-behavior: smooth;
  }
  .ant-modal-footer {
    margin: 0;
    padding: 0.8rem 1.25rem;
    box-shadow: 0px 0px 2.8rem rgba(167, 160, 137, 0.4);
  }

  // .ant-modal-content {
  //   max-height: 78vh;
  //   overflow: auto;
  //   scroll-behavior: smooth;
  // }
}

// .modal-fixed {
//   height: '68vh';
//   overflow-y: scroll;
//   padding: 5px;
// }

.ant-input,
.ant-input-affix-wrapper,
.ant-input-number,
.ant-select-multiple .ant-select-selector,
.ant-select-single .ant-select-selector {
  border-radius: var(--bs-app-border, 6px);
}

.ant-input,
.ant-input-affix-wrapper {
  padding: 0.6rem 0.8rem;
  border: 1px solid #ddd9cf;
}
.ant-form-item-explain {
  text-align: start;
}
.ant-select-single .ant-select-selector {
  align-items: center;
  .ant-select-selection-search-input {
    height: 100% !important;
  }
}

.ant-btn {
  // padding: 0.857rem;
  font-weight: 600;
  line-height: 100%;
  min-height: unset;
  height: unset;
  min-height: 2.56rem;
  min-width: 2.64rem;
  white-space: normal;
  // color: #222222;
  border-radius: var(--bs-app-border);

  &.ant-btn-dangerous {
    background-color: #fde1df;
  }

  &.small {
    padding: 0.6rem;
  }

  &:hover {
  }
}

.ant-btn-primary {
  box-shadow: none;
  border-color: transparent;
  &:not(:disabled) {
    background-color: var(--bs-button-bg, var(--bs-primary));
    border-color: var(--bs-button-border-color, var(--bs-primary));
    &:hover {
      background: var(--bs-button-hover-bg, var(--bs-primary));
      color: var(--bs-button-hover-color, var(--bs-button-color));
      border-color: var(--bs-button-hover-border-color, var(--bs-button-hover-bg, var(--bs-primary)));
    }
  }
}

.ant-tree {
  background: transparent;
}

.ant-table-cell {
  .ant-form-item .ant-form-item-control-input-content {
    .ant-btn {
      padding: 0.56rem;
      font-weight: 600;
      line-height: 100%;
      min-height: auto;
      height: auto;

      &:disabled {
        background-color: #f1f1f1;
      }
    }
  }
}

.box-loading {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
}

.ant-menu {
  .ant-menu-item {
    border-radius: var(--bs-app-border, 0.8rem);
    &.ant-menu-item-selected {
      font-weight: bold;
      color: #222222;
    }
    &.ant-menu-item-only-child {
      font-size: 0.95rem;
    }
  }

  .ant-menu-item,
  .ant-menu-submenu > .ant-menu-submenu-title {
    height: 2.857rem;
  }
}

svg {
  &.svg-check {
    display: inline;
    polyline {
      -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
      animation: checkmark 0.25s ease-in-out 0.7s backwards;
    }
    circle {
      -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
      animation: checkmark-circle 0.6s ease-in-out backwards;
    }
    circle#colored {
      -webkit-animation: colored-circle 0.6s ease-in-out 0.7s backwards;
      animation: colored-circle 0.6s ease-in-out 0.7s backwards;
    }
  }
}

.app-version {
  position: fixed;
  bottom: 0.5rem;
  left: 1.25rem;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 0.8rem;
  color: #fff;
  z-index: 1000;

  a {
    font-weight: 900;
    color: #fff;
    text-decoration: none;
  }

  &.logined {
    color: var(--bs-menu-side-color);
    a {
      color: var(--bs-menu-side-color);
    }
  }
}

.relative {
  position: relative;
}

.ant-alert {
  align-items: flex-start;
  color: #6b624c;
  border-radius: 0.5rem;
  svg {
    margin-top: 0.25rem;
    // width: 1.25rem;
    // height: auto;
  }

  &.ant-alert-info {
    background: #edf8fc;
    border: 1px solid #95d7ee;
    svg {
      fill: #13607c;
    }
  }

  &.ant-alert-warning {
    background: #fef5ec;
    border: 1px solid #f6b065;
    svg {
      fill: #743f07;
    }
  }

  &.ant-alert-danger {
    background: #fff2f1;
    border: 1px solid #faa7a1;

    svg {
      fill: #7b201a;
    }
  }
}

.btn-add-project {
  background: var(--bs-primary);
  border-radius: var(--bs-app-border, 0.571rem) ;
  padding: 0.9rem 1.1rem;
  font-size: 1.143rem;
  font-weight: 600;
  margin-bottom: 1.25rem;
  width: 100%;
  text-align: start;
  // color: #3c3001;
  height: unset;
}

.app-tabs {
  position: relative;
  background: #ffffff;
  border: 1px solid #ddd9cf;
  border-radius: var(--bs-app-border, 0.5rem);
  padding: 0.5rem;

  .l-active {
    position: absolute;
    width: calc(50% - 0.5rem);
    height: calc(100% - 1rem);
    background: var(--bs-button-hover-bg, var(--bs-primary));
    border-radius: var(--bs-app-border, 0.5rem);
    transition: all 0.3s linear;

    &.active-1 {
      transform: translateX(100%);
    }
  }

  .ant-btn {
    font-weight: 700;
    font-size: 1.2rem;
    height: 3rem;
    // color: var(--bs-button-color, #6b624c);

    &:hover {
      background-color: transparent;
    }

    &.active {
      color: var(--bs-button-color, #6b624c);
    }
  }
}

.login-register {
  .tabs {
    position: relative;
    background: #ffffff;
    border: 1px solid #ddd9cf;
    border-radius: var(--bs-app-border, 0.5rem);
    padding: 0.5rem;

    .l-active {
      position: absolute;
      width: calc(50% - 0.5rem);
      height: calc(100% - 1rem);
      background: var(--bs-button-hover-bg, var(--bs-primary));
      border-radius: var(--bs-app-border, 0.5rem);
      transition: all 0.3s linear;

      &.active-1 {
        transform: translateX(100%);
      }
    }

    .ant-btn {
      font-weight: 700;
      font-size: 1.2rem;
      height: 3rem;
      // color: var(--bs-button-color, #6b624c);

      &:hover {
        background-color: transparent;
      }

      &.active {
        color: var(--bs-button-color, #6b624c);
      }
    }
  }
}

.app-form {
  $height: 2.56rem;
  .ant-form-item-control-input {
    min-height: 2.64rem;
    .ant-form-item-control-input-content,
    .ant-input-affix-wrapper {
      height: 100%;
    }
  }
  .ant-input-number-input {
    height: 2.56rem;
  }
  .ant-input-number {
    height: 2.56rem;
  }
  .ant-input-number-group-wrapper {
    height: $height;
    .ant-input-number {
      height: $height;
    }
  }
  .ant-input-number-group {
    height: $height;
  }

  .ant-select,
  .ant-picker {
    height: 100%;
    border-radius: var(--bs-app-border, 0.8rem);
  }
  .ant-select-selector {
    min-height: 2.64rem;
  }
  .ant-select-single {
    .ant-select-selector {
      height: $height;
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        line-height: $height;
      }
    }
  }
  .ant-btn {
    // padding: 0.5rem 0.8rem;
    height: $height;
    padding-top: unset;
    padding-bottom: unset;
  }
}

// ant table custom
.ant-table {
  box-shadow: 0px 0px 2.8rem rgba(167, 160, 137, 0.4);
}
.ant-table-wrapper {
  .ant-table-container {
    table {
      >thead>tr:first-child>*:first-child {
        border-start-start-radius: var(--bs-app-border, 8px)
      }
      >thead>tr:last-child>*:last-child {
        border-start-end-radius: var(--bs-app-border, 8px)
      }
    }
  }
  .ant-table-content {
    overflow-x: auto;
  }

  .ant-table-tbody > tr {
    > td {
      padding: 0.9rem 1rem;
    }
    &.ant-table-row:hover > td {
      background: rgba(var(--bs-table-heading-rbg, var(--bs-primary-rgb)), 0.2);
    }
  }
  .ant-table-thead > tr {
    > td,
    > th {
      padding: 1rem;
      background-color: var(--bs-table-heading);
      color: var(--bs-table-heading-color);

      &.ant-table-column-sort {
        background-color: var(--bs-primary);
      }
      &.ant-table-column-has-sorters:hover {
        background-color: rgba(
          var(--bs-table-heading-rbg, var(--bs-primary-rgb)),
          0.8
        );
      }
    }
  }
}

// ant popover
.ant-popover {
  --antd-arrow-background-color: #282e3e;
  .ant-popover-content {
    .ant-popover-inner {
      background-color: #282e3e;
      .ant-popover-title,
      .ant-popover-inner-content {
        color: #ffffff;
      }
    }
  }
}

.part-group {
  > *:not(:first-child) {
    margin-left: 0;
  }
}
.ant-form-item {
  &.hidden-label {
    .ant-form-item-label {
      display: none;
    }
  }
}
.ant-form-item .ant-form-item-label > label,
.ant-descriptions .ant-descriptions-item-label {
  color: var(--label-form-color, #998c70);
  font-weight: 500;
}

#formQuantity,
#adaptForm {
  .ant-space-compact {
    .ant-form-item-row,
    .ant-form-item-control-input,
    .ant-form-item-control-input-content,
    .ant-btn {
      height: 100%;
    }
    .ant-btn.ant-btn-compact-first-item {
      border-right: none;
    }
    .ant-btn.ant-btn-compact-last-item {
      border-left: none;
    }
  }
  .ant-input-number-affix-wrapper {
    border-radius: 0;
  }
  .ant-input-number-group-addon {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #fafafa;
  }
  .ant-form-item-explain-error {
    position: absolute;
  }
  .ant-input-number-group-wrapper .ant-input-number {
    height: 2.51rem;
  }
  .ant-input-number-input-wrap {
    height: 100%;
  }
  .ant-input-number .ant-input-number-input {
    height: 100%;
  }
}
.statistic {
  margin-bottom: 1.5rem;
  .b-statistic {
    // background: #fef2c3;
    border-radius: var(--bs-app-border, 0.8rem);
    padding: 1.5rem 1.25rem;

    @media #{$tabletPortrait} {
      margin-bottom: 1.25rem;
    }

    label {
      margin-top: 0.5rem;
    }

    &.total-projects {
      background-color: var(--bs-table-heading);
    }
    &.total-parts {
      background-color: #fcd0cd;
    }
    &.total-calculations {
      background-color: #b8e4f4;
    }
    &.self-calculations {
      background-color: #f8c48b;
    }
    &.total-sales {
      background-color: #c0f1db;
    }
  }
}

.b-shadow {
  box-shadow: 0px 0px 2.8rem rgba(167, 160, 137, 0.4);
}

.ant-card {
  .ant-card-head,
  .ant-card-body {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .ant-card-body {
    padding: 1.25rem;
  }
}

.text-label {
  color: var(--label-form-color, #998c70);
  font-weight: 500;
}

.truncate {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grecaptcha-badge {
  display: none !important;
}

.text-ml-200px {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 100%;
  margin-bottom: -2px;
  pointer-events: none;
}

.text-ml-300px {
  max-width: 310px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 100%;
  margin-bottom: -2px;
  pointer-events: none;
}

.input-addon-w90 {
  max-width: 128px;
}

.n-input-w-130 {
  width: min(10vw, 130px);
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 18.666666666666668px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.d-group {
  position: relative;
  background: var(--t-bg-color, #fff);
  .group-title {
    position: absolute;
    top: 0;
    left: calc(1.25rem - 0.8rem);
    transform: translateY(-50%);
    background: var(--t-bg-color, #fff);
    width: fit-content !important;
    font-weight: bold !important;
    padding: 0 0.8rem;
  }
  &.ant-card-bordered {
    border: 1px solid #00000044;
  }
}

.btn-select {
  display: flex;
  align-items: center;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  padding: 0 11px;
  border-radius: var(--bs-app-border, 6px);
  height: 32px;
  max-width: 100%;

  text-decoration: none;
  gap: 1rem;
  cursor: pointer;

  &:hover {
    color: initial;
    border-color: rgba(var(--bs-primary-rgb), 0.7);
  }

  label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  .anticon {
    margin-left: auto;
    opacity: 0.25;
    color: #000;
  }
}

// START remove antd border

.ant-upload-wrapper {
  border-radius: 0 !important;
  .ant-upload {
    border-radius: 0 !important;
  }
}

.ant-select-dropdown, .ant-dropdown-menu, .ant-alert, .ant-card, .ant-image, .b-price-info, .img-thumbnail, .ant-input-number-group-addon, .ant-popover-inner, .ant-tag {
  border-radius: 0 !important;
}

// END remove antd border

.app-tag {
  --tag-color: rgba(var(--bs-primary-rgb), 0.2);
  background-color: var(--tag-color);
  border-color: var(--tag-color);
}